import React, { useState } from 'react';
import { Jumbotron as Jumbo, Container, Carousel } from 'react-bootstrap';
import styled from 'styled-components';
import shipImage1 from '../assets/boardsafety.jpeg';
import shipImage2 from '../assets/port.jpg';
import shipImage3 from '../assets/food.jpg';
import shipImage4 from '../assets/shipimage.jpeg';

export const Jumbotron = (props) => {
  const [index, setIndex] = useState(0);
  const user = props.user;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Jumbo fluid className="jumbo">
      <div className="overlay"></div>
      <Container className="text-container">
        {user ? (
          <>
            <h4>Dear {user.first_name},</h4>
            <h1>Welcome to Ornate Maritime Pvt Ltd</h1>
            <p>Marines preferred learning and training management platform</p>
          </>
        ) : (
          <>
            <h1>Welcome to Ornate Maritime Pvt Ltd</h1>
            <p>Marines preferred learning and training management platform</p>
          </>
        )}
      </Container>

      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        controls={true} 
        indicators={true} 
        interval={4000} 
        slide={true} 
        fade={false} 
      >
        <Carousel.Item>
          <img className="d-block w-100" src={shipImage1} alt="First slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={shipImage2} alt="Second slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={shipImage3} alt="Third slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={shipImage4} alt="Fourth slide" />
        </Carousel.Item>
      </Carousel>
    </Jumbo>
  );
};
