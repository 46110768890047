// import { useEffect, useState } from "react";
// import {Button} from "react-bootstrap";


// export const Otp = (props) => {
    
//     const [verified, setVerified] = useState(false);
    
//     useEffect(() => {
//         console.log('props updated');
        
//         if (props.verified == true) {
//             setVerified(true)
//         }
//         if (props.verified == false) {
//             setVerified(false)
//         }        
//     });

// //console.log("props verified : ", props.verified)
// //console.log("verified : ", verified)

// const  generateOtp = (e,type,key) => { 
//         e.preventDefault();      
//         console.log("generate otp: ", "key: ", key, "type: ", type)
//         // fetch("http://127.0.0.1:8000/api/otp",{
//             fetch("https://backend.ornatemaritime.net/api/otp", {
//         method: 'PUT',
//         body: JSON.stringify({
//             type : type,
//             key : key
//         }),
//         headers: {
//             "Content-type": "application/json"
//         }
//         }).then(function(response){    
//             console.log("generate otp response status code", response.status)            
//             if (response.status !==200) {
//             alert("Error generating otp")
//             props.setVerified(false)
//             return ""        
//             }  else {
//                 return response.json();
//             }             
//         })
//         .then(
//         (result) => {
//             console.log("generate otp result:", result)
//             //this.setOtp(e, key, "", result['id'], type, false)
//             var value
//             while(value == null) value = prompt("Please enter the otp received on your " + type + ". The otp is valid for 120 seconds")    
            
//             // fetch("http://127.0.0.1:8000/api/otp",{
//             fetch("https://backend.ornatemaritime.net/api/otp", {
//             method: 'POST',
//             body: JSON.stringify({
//                 id : result['id'],
//                 type : type,
//                 key : key,
//                 otp_entered : value,
//             }),
//             headers: {
//                 "Content-type": "application/json"
//             }
//             }).then(res => res)
//             .then(
//                 (result) => {
//                     console.log("result verify otp:", result.status)
//                     if (result.status === 200) {
//                         alert("Otp verified successfully")                
//                         props.setVerified(true)
//                         setVerified(true)
//                     } else if (result.status === 409) {
//                         alert("Otp entered is invalid")
//                         props.setVerified(false)             
//                     } else if (result.status === 410) {
//                         alert("Otp expired")
//                         props.setVerified(false)
//                     } else {
//                         alert("Error occurred")
//                         props.setVerified(false)
//                     }         
//                 },
//                 // Note: it's important to handle errors here
//                 // instead of a catch() block so that we don't swallow
//                 // exceptions from actual bugs in components.
//                 (error) => {
//                 alert("Error occurred verifying otp" + error)  
//                 props.setVerified(false)
//                 }) 
//             },          
//             (error) => {
//                 alert("Error occurred generating otp" + error)  
//                 props.setVerified(false)
//             })
//       }
    
//       return (
//           <Button size="sm" onClick={(e) => generateOtp(e, props.otptype, props.otpkey)} disabled={verified}>Verify</Button>
//       )
// }




import { useEffect, useState } from "react";
import {Button} from "react-bootstrap";


export const Otp = (props) => {
    
    const [verified, setVerified] = useState(false);
    
    useEffect(() => {
        console.log('props updated');
        
        if (props.verified == true) {
            setVerified(true)
        }
        if (props.verified == false) {
            setVerified(false)
        }        
    });

//console.log("props verified : ", props.verified)
//console.log("verified : ", verified)

const generateOtp = (e, type, key) => {
    e.preventDefault();
    console.log("generate otp: ", "key: ", key, "type: ", type);

    // Ask the user if they want to skip OTP verification
    const skipVerification = window.confirm("Do you want to skip OTP verification?");

    if (skipVerification) {
        // If the user wants to skip, set verification to true and exit
        props.setVerified(true);
        setVerified(true);
        return;
    }
    fetch("https://backend.ornatemaritime.net/api/otp", {
    // fetch("http://127.0.0.1:8000/api/otp", {
        method: "PUT",
        body: JSON.stringify({
            type: type,
            key: key,
        }),
        headers: {
            "Content-type": "application/json",
        },
    })
        .then(function (response) {
            console.log("generate otp response status code", response.status);
            if (response.status !== 200) {
                alert("Error generating otp");
                props.setVerified(false);
                return "";
            } else {
                return response.json();
            }
        })
        .then((result) => {
            console.log("generate otp result:", result);
            var value;
            while (value == null)
                value = prompt(
                    "Please enter the otp received on your " +
                        type +
                        ". The otp is valid for 120 seconds"
                );
            fetch("https://backend.ornatemaritime.net/api/otp", {
            // fetch("http://127.0.0.1:8000/api/otp", {
                method: "POST",
                body: JSON.stringify({
                    id: result["id"],
                    type: type,
                    key: key,
                    otp_entered: value,
                }),
                headers: {
                    "Content-type": "application/json",
                },
            })
                .then((res) => res)
                .then(
                    (result) => {
                        console.log("result verify otp:", result.status);
                        if (result.status === 200) {
                            alert("Otp verified successfully");
                            props.setVerified(true);
                            setVerified(true);
                        } else if (result.status === 409) {
                            alert("Otp entered is invalid");
                            props.setVerified(false);
                        } else if (result.status === 410) {
                            alert("Otp expired");
                            props.setVerified(false);
                        } else {
                            alert("Error occurred");
                            props.setVerified(false);
                        }
                    },
                    (error) => {
                        alert("Error occurred verifying otp" + error);
                        props.setVerified(false);
                    }
                );
        })
        .catch((error) => {
            alert("Error occurred generating otp" + error);
            props.setVerified(false);
        });
};

    
      return (
          <Button size="sm" onClick={(e) => generateOtp(e, props.otptype, props.otpkey)} disabled={verified}>Verify</Button>
      )
}

