import React from 'react'

export const Home = () => (
    <div>

<div className="home_card">
            <div class="container-wrap">
                <div class="row no-gutters">
                    <div class="col-md-3 d-flex services align-self-stretch py-5 px-4 ftco-animate bg-odd-card fadeInUp ftco-animated">
                        <div class="media block-6 d-block text-center">
                            <div class="icon d-flex justify-content-center align-items-center">
                                <span class="flaticon-teacher"></span>
                            </div>
                            <div class="media-body p-2 mt-3">
                                <h3 class="heading text-center" style={{ paddingLeft: '10px' }}><a href = "/courses"><u>Courses</u></a></h3>
                                {/* <p>Updating The Details Soon...</p> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex services align-self-stretch py-5 px-4 ftco-animate bg-even-card fadeInUp ftco-animated">
                        <div class="media block-6 d-block text-center">
                            <div class="icon d-flex justify-content-center align-items-center">
                                <span class="flaticon-reading"></span>
                            </div>
                            <div class="media-body p-2 mt-3">
                                <h3 class="heading"><u>Course Fees</u></h3>
                                {/* <p>Updating The Details Soon...</p> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex services align-self-stretch py-5 px-4 ftco-animate bg-odd-card fadeInUp ftco-animated">
                        <div class="media block-6 d-block text-center">
                            <div class="icon d-flex justify-content-center align-items-center">
                                <span class="flaticon-books"></span>
                            </div>
                            <div class="media-body p-2 mt-3">
                                {/* <h3 class="heading">Book &amp; Library</h3> */}
                                <h3 class="heading"><a href="/library">Course Material</a></h3>
                                {/* <p>Updating The Details Soon...</p> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex services align-self-stretch py-5 px-4 ftco-animate bg-even-card fadeInUp ftco-animated">
                        <div class="media block-6 d-block text-center">
                            <div class="icon d-flex justify-content-center align-items-center">
                                <span class="flaticon-diploma"></span>
                            </div>
                            <div className="media-body p-2 mt-3">
                                    <h3 className="heading"><a href="#footer" style={{ color: 'inherit', textDecoration: 'none' }}><u>Marlins Approved</u></a></h3>
                                    {/* <p>Updating The Details Soon...</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <section className="about_section">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-5 col-md-6 about_left">
                        <img className="img-fluid" src="assets/img/groupstudy.jpg" alt="About" />
                    </div>
                    <div className="col-lg-6 offset-md-0 col-md-12 about_right">
                        <h1><u>ABOUT</u></h1>
                        <div className="about_wow" data-wow-duration="1s">
                            <p className="about_lead">
                                Ornate Maritime Pvt Ltd is located in Navi Mumbai, with representative offices in Belapur (Navi Mumbai) and Patna (Bihar). The company is dedicated to providing the best possible customer service in the field of educational training.
                            </p>
                            <p className="about_lead">
                                The commitment of top management has ensured that this is not an exercise in mere paperwork. The benefits experienced from working within a structured and streamlined organization have enabled the company to provide a world-class, professional, and reliable service to our customers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        
        <hr />

        <section class="ftco-section ftco-counter img section-gap" id="section-counter" data-stellar-background-ratio="0.5">
            <div class="container">
                <div class="row justify-content-center mb-5 pb-2 d-flex">

                    <div class="col-lg-6 offset-md-0 col-md-12 about-right">
                        <h2 ><u>Our services</u></h2>
                        <p className="lead">Excellent In house training services.</p>
                        {/* <br></br>
                        <br></br>
                        <br></br>
                        <br></br> */}
                        <h3><u>TRAINING</u></h3>
                        <p className="lead">Flag State Representation & Crew Documentation.</p>
                    </div>

                    <div class="col-lg-6 col-md-6 about-left">
                        <img class="img-fluid" src="assets/img/cargoworker.jpg" alt="no photo found" />
                    </div>
                </div>
            </div>
        </section>
        <hr />

        <section class="about-area section-gap" id='team_work'>
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-5 col-md-6 about-left">
                        <img class="img-fluid" src="assets/img/img5.jpg" alt="" />
                    </div>
                    <div class="col-lg-6 offset-md-0 col-md-12 about-right">
                        <h1><u>
                            Team work</u>
                        </h1>
                        <div class="wow fadeIn" data-wow-duration="1s" >
                            <p className="lead">We believe in team work. It maximizes the individual strength of 
each person working towards a shared goal and committed in 
attaining them.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="feature-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 mb-3">
                        <div class="section-title text-center">
                            <h1>Our featured courses</h1>
                        </div>
                    </div>
                </div>
                <div class="feature-inner row">
                    <div class="col-lg-4 col-md-6 stretch">
                        <div class="feature-item">
                            <i class="ti-crown"></i>
                            <h4>Hydrogen Sulphide</h4>
                            <div class="wow fadeIn cards_2" data-wow-duration="1s" data-wow-delay=".1s" >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 stretch">
                        <div class="feature-item">
                            <i class="ti-briefcase"></i>
                            <h4>Helicopter Landing Assistance</h4>
                            <div class="wow fadeIn cards_2" data-wow-duration="1s" data-wow-delay=".3s" >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 stretch">
                        <div class="feature-item">
                            <i class="ti-medall-alt"></i>
                            <h4>Food Handling</h4>
                            <div class="wow fadeIn cards_2" data-wow-duration="1s" data-wow-delay=".5s" >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 stretch">
                        <div class="feature-item">
                            <i class="ti-key"></i>
                            <h4>TEST COURSE</h4>
                            <div class="wow fadeIn cards_2" data-wow-duration="1s" data-wow-delay=".1s" >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 stretch">
                        <div class="feature-item">
                            <i class="ti-files"></i>
                            <h4>Hazardous Material Transportation</h4>
                            <div class="wow fadeIn cards_2" data-wow-duration="1s" data-wow-delay=".3s" >
                                {/* <p>
                                    Lorem ipsum dolor sit amet consec tetur adipisicing elit, sed do eiusmod tempor incididunt labore.
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 stretch">
                        <div class="feature-item">
                            <i class="ti-headphone-alt"></i>
                            <h4>HAZMAT</h4>
                            <div class="wow fadeIn cards_2" data-wow-duration="1s" data-wow-delay=".5s" >
                                {/* <p>
                                    Lorem ipsum dolor sit amet consec tetur adipisicing elit, sed do eiusmod tempor incididunt labore.
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="portfolio-modal modal fade" id="portfolioModalh2s" tabindex="-1" aria-labelledby="portfolioModalh2s" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body text-center pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">

                                    <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">Hydrogen Sulphide Safety</h2>

                                    <div class="divider-custom">
                                        <div class="divider-custom-line"></div>
                                        <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                                        <div class="divider-custom-line"></div>
                                    </div>

                                    <img class="img-fluid rounded mb-5" src="assets/img/portfolio/hs.png" alt="..." />

                                    <p class="mb-4">This course has been compiled to apprise the reader about the characteristics of this poisonous gas, the various health and other hazards associated it had most importantly on how one can protect himself against it.
                                        The course hasbeen composed in a user friendly manner starting from the basics and then progressively going on to more advanced subjects like H2S detection and contingency planning</p>
                                    <button class="btn btn-primary" data-bs-dismiss="modal">
                                        <i class="fas fa-times fa-fw"></i>
                                        Close Window
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="portfolio-modal modal fade" id="portfolioModalcook" tabindex="-1" aria-labelledby="portfolioModalcook" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body text-center pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">

                                    <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">Chief Cook</h2>

                                    <div class="divider-custom">
                                        <div class="divider-custom-line"></div>
                                        <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                                        <div class="divider-custom-line"></div>
                                    </div>

                                    <img class="img-fluid rounded mb-5" src="assets/img/portfolio/cook.png" alt="..." />

                                    <p class="mb-4">
                                        This course is aimed at existing second cooks in preparation for promotion to
                                        Chief Cook. The course provides training in developing diverse cuisines including trade tests
                                        to increase individuals cooking variety, including nutritional and dietary
                                        management for crew and overall improvement of cooking and
                                        Galley management skills.
                                    </p>
                                    <button class="btn btn-primary" data-bs-dismiss="modal">
                                        <i class="fas fa-times fa-fw"></i>
                                        Close Window
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
)
